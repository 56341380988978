import React, { useState } from "react";
import { Carousel } from "react-bootstrap"; // Ensure Bootstrap is installed
import images from "../../images/images"; // Adjust the path to your images folder
import Form from "../../CommonCompounds/Form/Form";
import Form2 from "../../CommonCompounds/Form2/Form2";
import Itinerary from "./itineray-pack01";
import "bootstrap/dist/css/bootstrap.min.css";

function Index() {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  return (
    <>
      {/* Hero Banner with Carousel */}
      <Carousel indicators={true} controls={true}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.banner1} // Replace with actual image paths
            alt="Ooty Package"
            style={{ height: "650px", objectFit: "cover" }}
          />
          <Carousel.Caption className="text-center">
            <h1 className="text-white">
            OOTY 2NIGHTS AND 3DAYS COUPLE PACKAGE
            </h1>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.banner2} // Replace with actual image paths
            alt="Ooty Package"
            style={{ height: "650px", objectFit: "cover" }}
          />
          <Carousel.Caption className="text-center">
            <h1 className="text-white">
            OOTY 2NIGHTS AND 3DAYS COUPLE PACKAGE
            </h1>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.banner3} // Replace with actual image paths
            alt="Ooty Package"
            style={{ height: "650px", objectFit: "cover" }}
          />
          <Carousel.Caption className="text-center">
            <h1 className="text-white">
            OOTY 2NIGHTS AND 3DAYS COUPLE PACKAGE
            </h1>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.banner4} // Replace with actual image paths
            alt="Ooty Package"
            style={{ height: "650px", objectFit: "cover" }}
          />
          <Carousel.Caption className="text-center">
            <h1 className="text-white">
            OOTY 2NIGHTS AND 3DAYS COUPLE PACKAGE
            </h1>
          </Carousel.Caption>
        </Carousel.Item>

      </Carousel>

      <section>
        <div className="container my-80">
            <div className="row">
                {/* Package Overview Section */}
                <div className="col-lg-8 col-md-12">

                    <div className="container ">
                        <h2>
                        Package{" "}
                        <span className="text-warning">Overview</span>
                        </h2>
                        <div className="d-flex align-items-center mt-3 overflow-auto">
                        <span className="googlereviews me-2"> <h4>4.1</h4></span> 
                        <span className="googleratings me-3 mb-3">★★★★☆</span>
                        <span className="googlereviews mb-2">(361+ Google Reviews)</span>
                        </div>
                        <p className="mt-1">
                        Ooty offers a serene stay with amenities like complimentary Wi-Fi, ample guest parking, and vegetarian meals at its in-house restaurant, ensuring comfort and convenience. Its strategic location near Ooty's popular attractions, such as Doddabetta Peak and the Botanical Garden, makes it an excellent choice for sightseeing enthusiasts. The clean and spacious rooms cater well to families and solo travelers alike, creating a cozy environment for relaxation. 
                        </p>
                    </div>
                    <section>
                        <Itinerary />
                    </section>
                </div>

                {/* Quote Form Section */}
                <div className="col-md-12 col-lg-4 overview">
                <div className="sticky-form" style={{ position: 'sticky', top: '100px', zIndex: 10 }}>
                    <Form2 />
                    <div className="container">
                    <div className="row d-flex jutify-content-center align-items-center bg-infoo py-4 mt-4">
                        <div className="col-5">
                            <h className="price-booknow">
                            ₹ 5999{' '} <br />
                            <span
                                className="perperson"
                                style={{ fontSize: '16px', color: '#606060' }} >
                            per person
                            </span>
                            </h>
                        </div>
                        <div className="col-7">
                    <button type="submit" href="" className="btn btn-bknw w-100">
                        BOOK NOW
                    </button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

            </div>
        </div>
      </section>



      {/* Render the Form component and pass the necessary props */}
      <Form show={showForm} handleClose={handleCloseForm} />
    </>
  );
}


export default Index;
