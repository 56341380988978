import React, { useState } from "react";
import { Carousel } from "react-bootstrap"; // Ensure Bootstrap is installed
import images from "../../images/images"; // Adjust the path to your images folder
import Form from "../../CommonCompounds/Form/Form";
import Form2 from "../../CommonCompounds/Form2/Form2";
import Itinerary from "./itineray-pack01";
import "bootstrap/dist/css/bootstrap.min.css";

function Index() {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  return (
    <>
      {/* Hero Banner with Carousel */}
      <Carousel indicators={true} controls={true}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.chikbanner1} // Replace with actual image paths
            alt="Ooty Package"
            style={{ height: "650px", objectFit: "cover" }}
          />
          <Carousel.Caption className="text-center">
            <h1 className="text-white">
            CHIKMANGALUR 2NIGHTS AND 3DAYS COUPLE PACKAGE
            </h1>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.chikbanner2} // Replace with actual image paths
            alt="Ooty Package"
            style={{ height: "650px", objectFit: "cover" }}
          />
          <Carousel.Caption className="text-center">
            <h1 className="text-white">
            CHIKMANGALUR 2NIGHTS AND 3DAYS COUPLE PACKAGE
            </h1>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.chikbanner3} // Replace with actual image paths
            alt="Ooty Package"
            style={{ height: "650px", objectFit: "cover" }}
          />
          <Carousel.Caption className="text-center">
            <h1 className="text-white">
            CHIKMANGALUR 2NIGHTS AND 3DAYS COUPLE PACKAGE
            </h1>
          </Carousel.Caption>
        </Carousel.Item>

        

      </Carousel>

      <section>
        <div className="container my-80">
            <div className="row">
                {/* Package Overview Section */}
                <div className="col-lg-8 col-md-12">

                    <div className="container ">
                        <h2>
                        Package{" "}
                        <span className="text-warning">Overview</span>
                        </h2>
                        
                        <div className="d-flex align-items-center mt-3 overflow-auto">
                        <span className="googlereviews me-2"> <h4>4.8</h4></span> 
                        <span className="googleratings me-3 mb-3">★★★★★</span>
                        <span className="googlereviews mb-2">(100+ Google Reviews)</span>
                        </div>
                        <p className="mt-1">
                        Chikmagalur, known as the "Coffee Land of Karnataka," is a scenic hill station nestled in the Western Ghats. It is famous for its vast coffee plantations, lush green hills, and attractions like Mullayanagiri, Baba Budangiri, and Hebbe Falls. The cool climate and misty landscapes make it a perfect destination for nature lovers and adventure seekers. With its serene beauty and rich coffee culture, Chikmagalur offers a refreshing getaway.                        </p>
                    </div>
                    <section>
                        <Itinerary />
                    </section>
                </div>

                {/* Quote Form Section */}
                <div className="col-md-12 col-lg-4 overview">
                <div className="sticky-form" style={{ position: 'sticky', top: '100px', zIndex: 10 }}>

                    <div className="container">
                      <div className="row d-flex jutify-content-center align-items-center bg-infooo py-4 mb-2">
                          <div className="col-5">
                              <p className="price-booknow">
                                ₹8999
                              </p>
                              <p className="perperson mb-0"style={{ fontSize: '14px', color: '#606060' }} >               
                                per person
                              </p>
                          </div>
                          <div className="col-7">
                            <button type="submit" href="" className="btn btn-bknw w-100">
                                BOOK NOW
                            </button>
                          </div>
                      </div>
                    </div>
                    <Form2 />
                </div>
                </div>

            </div>
        </div>
      </section>



      {/* Render the Form component and pass the necessary props */}
      <Form show={showForm} handleClose={handleCloseForm} />
    </>
  );
}


export default Index;
