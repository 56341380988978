import React, { useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import images from '../../images/images';
import { NavLink } from 'react-router-dom';

const categories = ['All', '2N3D', '3N4D', '4N5D', 'Honeymoon',];

const packages = [
  {
    id: 1, title: "Alleppey-Thekkady Special", duration: "2N3D", description: "Full Sightseeing",
    oldPrice: "₹12,000", price: "₹9,999", imageUrl: images.alleppeypack02, person: "per person",
    package: "https://ootytourpackages.bookonetour.in/Lablooms", rating: 4.5, category: "2N3D"
  },
  {
    id: 2, title: "Alleppey-Vagamon Special", duration: "2N3D", description: "Full Sightseeing",
    oldPrice: "₹11,500", price: "₹9,499", imageUrl: images.vagamonpack02, person: "per person",
    package: "https://ootytourpackages.bookonetour.in/kottaram", rating: 4.3, category: "2N3D"
  },
  {
    id: 3, title: "Trivandrum-Varkala Special", duration: "2N3D", description: "Full Sightseeing",
    oldPrice: "₹10,000", price: "₹8,999", imageUrl: images.varkalapackpack, person: "per person",
    package: "https://ootytourpackages.bookonetour.in/centralresidency", rating: 4.7, category: "3N4D"
  },
  {
    id: 4, title: "Varkala-Vagamon Special", duration: "2N3D", description: "Full Sightseeing",
    oldPrice: "₹10,000", price: "₹8,999", imageUrl: images.vagamonpack, person: "per person",
    package: "https://ootytourpackages.bookonetour.in/lazoSliverlake", rating: 4.9, category: "Honeymoon"
  },
  {
    id: 5, title: "Alleppey-Thekkady Special", duration: "3N4D", description: "Full Sightseeing",
    oldPrice: "₹14,500", price: "₹12,499", imageUrl: images.alleppeypack, person: "per person",
    package: "https://ootytourpackages.bookonetour.in/Lablooms", rating: 4.5, category: "2N3D"
  },
  {
    id: 6, title: "Munnar-Alleppey Special", duration: "3N4D", description: "Full Sightseeing",
    oldPrice: "₹12,500", price: "₹10,499", imageUrl: images.munnarpack02, person: "per person",
    package: "https://ootytourpackages.bookonetour.in/kottaram", rating: 4.3, category: "2N3D"
  },
  {
    id: 7, title: "Trivandrum-Varkala-Alleppey Special", duration: "3N4D", description: "Full Sightseeing",
    oldPrice: "₹15,500", price: "₹13,499", imageUrl: images.trivandrumpack, person: "per person",
    package: "https://ootytourpackages.bookonetour.in/centralresidency", rating: 4.7, category: "3N4D"
  },
  {
    id: 8, title: "Alleppey-Thekkady-Munnar Special", duration: "4N5D", description: "Full Sightseeing",
    oldPrice: "₹16,500", price: "₹14,999", imageUrl: images.munnarpack, person: "per person",
    package: "https://ootytourpackages.bookonetour.in/lazoSliverlake", rating: 4.9, category: "Honeymoon"
  },
  {
    id: 9, title: "Munnar-Thekkady-Alleppey-Kovalam-", duration: "4N5D", description: "Full Sightseeing",
    oldPrice: "₹20,000", price: "₹17,999", imageUrl: images.thekkadypack, person: "per person",
    package: "https://ootytourpackages.bookonetour.in/lazoSliverlake", rating: 4.7, category: "Honeymoon"
  },
  {
    id: 10, title: "Munnar-Thekkady-Alleppey-Kovalam-", duration: "5N6D", description: "Full Sightseeing",
    oldPrice: "₹23,000", price: "₹20,999", imageUrl: images.kovalampack, person: "per person",
    package: "https://ootytourpackages.bookonetour.in/lazoSliverlake", rating: 4.6, category: "Honeymoon"
  }
];

const PackageCard = ({ pkg }) => {
  const iconDetails = [
    { src: images.package1, text: 'PRIVATE CAB' },
    { src: images.package2, text: 'SIGHTSEEING' },
    { src: images.package3, text: 'HOTEL STAY' },
    { src: images.package4, text: 'BREAKFAST' },
    { src: images.package5, text: 'TOUR GUIDE' },
  ];

  return (
    <div className="col-md-6 col-sm-12 col-lg-4 card_space">
      <a href={pkg.package} className="card" target="_blank" rel="noopener noreferrer">
        <div className="position-relative">
          <img src={pkg.imageUrl} alt={pkg.title} className="card-img-top p-2" style={{ width: '100%', height: 'auto' }} />
          <div className="position-absolute top-0 end-0 m-3">
            <div className="bg-success text-white rounded-pill px-2 py-1 mx-2 my-2">
              <i className="bi bi-star-fill me-1"></i> {pkg.rating}
            </div>
          </div>
        </div>
        <div className="card-body">
          <h3 className="card-title">{pkg.title}</h3>
          <p className="card-text">
            <span style={{ color: '#e54040' }}>{pkg.duration || 'N/A'}</span> {pkg.description}
          </p>
          <ul className="viewpoints-icons">
            {iconDetails.map((icon, index) => (
              <li key={index}>
                <img src={icon.src} alt="" /><br />
                <span className="icon-text">{icon.text}</span>
              </li>
            ))}
          </ul>
          <div className="price-wrapper">
            <h3 className="price">
              {pkg.oldPrice && (
                <span style={{ textDecoration: 'line-through', color: '#e54040', marginRight: '8px', fontSize: '0.8em' }}>
                  <span style={{ color: 'black' }}>{pkg.oldPrice}</span>
                </span>
              )}
              {pkg.price}{' '}
              <span className="perperson" style={{ fontSize: '0.8em', color: '#606060' }}>{pkg.person}</span>
            </h3>
          </div>
        </div>
      </a>
    </div>
  );
};

const PackageList = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const filteredPackages = selectedCategory === 'All' ? packages : packages.filter(pkg => pkg.category === selectedCategory);

  return (
    <div className="container pb-4 pt-4">
      {/* <div className="row">
        <div className="col px-4 mb-2 text-start head">
          <h2>Ooty Offer Packages!</h2>
          <p>Discover curated packages for your perfect vacation.</p>
        </div>
      </div> */}
      <div className="row mb-4 px-2">
        <div className="col text-left">
          {categories.map((category) => (
            <button
              key={category}
              className={`btn mx-2 ${selectedCategory === category ? 'btn-primary' : 'btn-outline-primary'}`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      <div className="row">
        {filteredPackages.length > 0 ? (
          filteredPackages.map(pkg => <PackageCard key={pkg.id} pkg={pkg} />)
        ) : (
          <div className="col text-center"><p>No packages available for this category.</p></div>
        )}
      </div>
    </div>
  );
};

export default PackageList;