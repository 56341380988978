import React from "react";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Header from "../../CommonCompounds/Header/Header.jsx";
import KeralaListinglisting from "../../Components/KeralaListing/keralaListing.jsx";


function listingPage(){
    return(
        <>
        <Header />
        <KeralaListinglisting />
        <Footer />
        </>
    )
}

export default listingPage;