import React from "react";
import Header from "../../CommonCompounds/Header/Header.jsx";
import OotyPackageCarousel from "../../CommonCompounds/OotyPackageCarousel/OotyPackageCarousel.jsx"
import Index from "../../Components/HomePage/HomePage.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Couple from "../../CommonCompounds/Couplepackage/Couplepackage.jsx"
import TestimonialCarousel from "../../CommonCompounds/TestimonialCarousel/TestimonialCarousel.jsx";
import OfferPackageList from "../../CommonCompounds/OfferPackages/OfferPackages.jsx";

function HomePage(){
    return(
        <>
        <Header />
        <OotyPackageCarousel />
        <OfferPackageList />
        <Couple />  
        <TestimonialCarousel />
        <Index />
        <Footer />
        </>
    )
}

export default HomePage;