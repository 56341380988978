
import Logo from "../Assets/images/logo.png";
import luggage from "../Assets/images/luggage 1.svg";
import booking from "../Assets/images/booking 1.svg";
import customer from "../Assets/images/best-customer-experience 1.svg";
import reward from "../Assets/images/reward 1.svg";
import Group from "../Assets/images/Group 1341.svg";
import package1 from "../Assets/images/a (1).png";
import package2 from "../Assets/images/a (2).png";
import package3 from "../Assets/images/a (3).png";
import package4 from "../Assets/images/a (4).png";
import package5 from "../Assets/images/a (5).png";
import point from "../Assets/images/point.svg";
import download from "../Assets/images/download.svg";
import iconlink from "../Assets/images/Icon Frame.svg";
import iconlink2 from "../Assets/images/mail.svg";
import iconlink3 from "../Assets/images/icons link.svg";
import Google from "../Assets/images/Google.png";
import CouplePackage from "../Assets/images/Rectangle 21.png";

import Rightcircle from "../Assets/images/Right circle.svg"
import leftcircle from "../Assets/images/Left circle.svg";
import green from "../Assets/images/green.svg";
import CouplePackage2 from "../Assets/images/Couple Package 3 (1).svg";
import CouplePackage3 from  "../Assets/images/Couple Package 3 (2).svg";
import person1 from "../Assets/images/person.png";
import person2 from "../Assets/images/person2.png";
import person3 from "../Assets/images/person3.png";
import Packagedownload  from  "../Assets/images/package download.svg";

import wayanadpack from "../Assets/images/wayanadpackage.png";
import manalipack from "../Assets/images/manalipackage.png";
import coorgpack from "../Assets/images/coorgpackage.png";
import chikpack from "../Assets/images/chikpackage.png";
import kodaikanalpack from "../Assets/images/kodaikanalpackage.png";
import munnarpack from "../Assets/images/munnarpackage.png";
import yercaudpack from "../Assets/images/yercaudpackage.png";
import ootypack from "../Assets/images/ootypackage.png";
import alleppeypack from "../Assets/images/alleppeypackage.png";
import vagamonpack from "../Assets/images/vagamonpackage.png";
import goapack from "../Assets/images/goapackage.png";
import varkalapack from "../Assets/images/varakalapack.png";
import trivandrumpack from "../Assets/images/trivandrumpack.png";
import thekkadypack from "../Assets/images/thekkadypack.png";
import kovalampack from "../Assets/images/kovalampack.png";

import wayanadpack02 from "../Assets/images/wayanadpackage02.png";
import manalipack02 from "../Assets/images/manalipackage02.png";
import coorgpack02 from "../Assets/images/coorgpackage02.png";
import chikpack02 from "../Assets/images/chikpackage02.png";
import kodaikanalpack02 from "../Assets/images/kodaikanalpackage02.png";
import munnarpack02 from "../Assets/images/munnarpackage02.png";
import yercaudpack02 from "../Assets/images/yercaudpackage02.png";
import ootypack02 from "../Assets/images/ootypackage02.png";
import alleppeypack02 from "../Assets/images/alleppeypackage02.png";
import vagamonpack02 from "../Assets/images/vagamonpackage02.png";
import goapack02 from "../Assets/images/goapackage02.png";

import herobannerimg from "../Assets/images/herobannerimg.png";
import ootydestination from "../Assets/images/ootydestination.png";
import kodaikanaldestination from "../Assets/images/kodaikanaldestination.png";
import chikmagalurdestination from "../Assets/images/chikmagalurdestination.png";
import wayanddestination from "../Assets/images/wayanddestination.png";
import alleppeydestionation from "../Assets/images/alleppeydestionation.png";
import vagamondestination from "../Assets/images/vagamondestination.png";
import munnardestination from "../Assets/images/munnardestination.png";
import coorgdestination from "../Assets/images/coorgdestination.png";
import andamandestination from "../Assets/images/andamandestination.png";
import sikkimdestination from "../Assets/images/sikkimdestination.png";
import manalidestination from "../Assets/images/manalidestination.png";
import goadestination from "../Assets/images/goadestination.png";


import offer1 from "../Assets/images/offer1.jpg";
import offer2 from "../Assets/images/offer2.jpg";
import offer3 from "../Assets/images/offer3.jpg";
import offer4 from "../Assets/images/offer4.jpg";


import destinations from "../Assets/images/destinations.svg";
import packages from "../Assets/images/packages.svg";
import assistance from "../Assets/images/assistance.svg";
import happyclients from "../Assets/images/happyclients.svg";
import bookings from "../Assets/images/bookings.svg";
import experience from "../Assets/images/experience.svg";

import ootybanner from "../Assets/images/ootybanner.jpg";

import offersimg from "../Assets/images/offersimg.svg";

import ootycarousel01 from "../Assets/images/carouselbanner01.jpg";
import ootycarousel02 from "../Assets/images/carouselbanner02.jpg";
import ootycarousel03 from "../Assets/images/carouselbanner03.jpg";
import ootycarousel04 from "../Assets/images/carouselbanner04.jpg";

import lablooms from "../Assets/images/lablooms.png";
import kluneymonar from "../Assets/images/kluneymanor.png";
import majestic from "../Assets/images/majesticcrown.png";
import centralresidency from "../Assets/images/centralresidency.png";
import sinclairsretreat from "../Assets/images/sinclaisretreat.png";
import mountainretreat from "../Assets/images/mountainretreat.png";
import hotelutimate from "../Assets/images/hotelultimate.png";
import hotelgrange from "../Assets/images/hotelgrange.png";
import kottaramresidency from "../Assets/images/kottaramresidency.png";
import silverlakeview from "../Assets/images/silverlakeview.png";
import bykesunshine from "../Assets/images/bykesunshine.png";
import yantraleisure from "../Assets/images/yantraleisure.png";
import delightzinn from "../Assets/images/delightzinn.png";
import vinayagainn from "../Assets/images/poppy.png";

import banner1 from "../Assets/images/banner1.jpeg";
import banner2 from "../Assets/images/banner2.png";
import banner3 from "../Assets/images/banner3.png";
import banner4 from "../Assets/images/banner4.png";

import day101 from "../Assets/images/day1-01.webp";
import day102 from "../Assets/images/day1-02.webp";
import day103 from "../Assets/images/day1-03.webp";
import day104 from "../Assets/images/day1-04.webp";
import day105 from "../Assets/images/day1-05.webp";
import day106 from "../Assets/images/day1-06.webp";

import day201 from "../Assets/images/day2-01.webp";
import day202 from "../Assets/images/day2-02.webp";
import day203 from "../Assets/images/day2-03.webp";
import day204 from "../Assets/images/day2-04.webp";
import day205 from "../Assets/images/day2-05.webp";
import day206 from "../Assets/images/day2-06.webp";

import day301 from "../Assets/images/day3-01.webp";
import day302 from "../Assets/images/day3-02.webp";
import day303 from "../Assets/images/day3-03.webp";
import day304 from "../Assets/images/day3-04.webp";
import day305 from "../Assets/images/day3-05.webp";
import day306 from "../Assets/images/day3-06.webp";








import coorgbanner1 from "../Assets/images/coorgbanner1.webp";
import coorgbanner2 from "../Assets/images/coorgbanner2.webp";
import coorgbanner3 from "../Assets/images/coorgbanner3.webp";

import day101coorg from "../Assets/images/day1-01coorg.webp";
import day102coorg from "../Assets/images/day1-02coorg.webp";
import day103coorg from "../Assets/images/day1-03coorg.webp";
import day104coorg from "../Assets/images/day1-04coorg.webp";
import day105coorg from "../Assets/images/day1-05coorg.webp";
import day106coorg from "../Assets/images/day1-06coorg.webp";

import day201coorg from "../Assets/images/day2-01coorg.webp";
import day202coorg from "../Assets/images/day2-02coorg.webp";
import day203coorg from "../Assets/images/day2-03coorg.webp";
import day204coorg from "../Assets/images/day2-04coorg.webp";
import day205coorg from "../Assets/images/day2-05coorg.webp";
import day206coorg from "../Assets/images/day2-06coorg.webp";

import day301coorg from "../Assets/images/day3-01coorg.webp";
import day302coorg from "../Assets/images/day3-02coorg.webp";
import day303coorg from "../Assets/images/day3-03coorg.webp";
import day304coorg from "../Assets/images/day3-04coorg.webp";
import day305coorg from "../Assets/images/day3-05coorg.webp";



import wayanadbanner1 from "../Assets/images/wayanadbanner1.webp";
import wayanadbanner2 from "../Assets/images/wayanadbanner2.webp";
import wayanadbanner3 from "../Assets/images/wayanadbanner3.webp";

import wayanadday101 from "../Assets/images/wayanadday1-01.webp";
import wayanadday102 from "../Assets/images/wayanadday1-02.webp";
import wayanadday103 from "../Assets/images/wayanadday1-03.webp";
import wayanadday104 from "../Assets/images/wayanadday1-04.webp";
import wayanadday105 from "../Assets/images/wayanadday1-05.webp";
import wayanadday106 from "../Assets/images/wayanadday1-06.webp";

import wayanadday201 from "../Assets/images/wayanadday2-01.webp";
import wayanadday202 from "../Assets/images/wayanadday2-02.webp";
import wayanadday203 from "../Assets/images/wayanadday2-03.webp";
import wayanadday204 from "../Assets/images/wayanadday2-04.webp";
import wayanadday205 from "../Assets/images/wayanadday2-05.webp";
import wayanadday206 from "../Assets/images/wayanadday2-06.webp";

import wayanadday301 from "../Assets/images/wayanadday3-01.webp";
import wayanadday302 from "../Assets/images/wayanadday3-02.webp";
import wayanadday303 from "../Assets/images/wayanadday3-03.webp";
import wayanadday304 from "../Assets/images/wayanadday3-04.webp";
import wayanadday305 from "../Assets/images/wayanadday3-05.webp";
import wayanadday306 from "../Assets/images/wayanadday3-06.webp";




import chikbanner1 from "../Assets/images/chikbanner1.png";
import chikbanner2 from "../Assets/images/chikbanner2.png";
import chikbanner3 from "../Assets/images/chikbanner3.png";

import chikday101 from "../Assets/images/chikday1-01.png";
import chikday102 from "../Assets/images/chikday1-02.png";
import chikday103 from "../Assets/images/chikday1-03.png";
import chikday104 from "../Assets/images/chikday1-04.png";
import chikday105 from "../Assets/images/chikday1-05.png";
import chikday106 from "../Assets/images/chikday1-06.png";

import chikday201 from "../Assets/images/chikday2-01.png";
import chikday202 from "../Assets/images/chikday2-02.png";
import chikday203 from "../Assets/images/chikday2-03.webp";
import chikday204 from "../Assets/images/chikday2-04.webp";
import chikday205 from "../Assets/images/chikday2-05.webp";
import chikday206 from "../Assets/images/chikday2-06.webp";

import chikday301 from "../Assets/images/chikday3-01.webp";
import chikday302 from "../Assets/images/chikday3-02.webp";
import chikday303 from "../Assets/images/chikday3-03.webp";
import chikday304 from "../Assets/images/chikday3-04.webp";
import chikday305 from "../Assets/images/chikday3-05.webp";
import chikday306 from "../Assets/images/chikday3-06.webp";


import kodaibanner1 from "../Assets/images/kodaibanner1.jpg";
import kodaibanner2 from "../Assets/images/kodaibanner2.png";
import kodaibanner3 from "../Assets/images/kodaibanner3.png";
import kodaiday101 from "../Assets/images/kodaiday1-01.webp";
import kodaiday102 from "../Assets/images/kodaiday1-02.webp";
import kodaiday103 from "../Assets/images/kodaiday1-03.webp";
import kodaiday104 from "../Assets/images/kodaiday1-04.webp";
import kodaiday105 from "../Assets/images/kodaiday1-05.webp";
import kodaiday106 from "../Assets/images/kodaiday1-06.webp";
import kodaiday201 from "../Assets/images/kodaiday2-01.webp";
import kodaiday202 from "../Assets/images/kodaiday2-02.webp";
import kodaiday203 from "../Assets/images/kodaiday2-03.webp";
import kodaiday204 from "../Assets/images/kodaiday2-04.webp";
import kodaiday205 from "../Assets/images/kodaiday2-05.webp";
import kodaiday206 from "../Assets/images/kodaiday2-06.webp";
import kodaiday301 from "../Assets/images/kodaiday3-01.webp";
import kodaiday302 from "../Assets/images/kodaiday3-02.webp";
import kodaiday303 from "../Assets/images/kodaiday3-03.webp";
import kodaiday304 from "../Assets/images/kodaiday3-04.webp";
import kodaiday305 from "../Assets/images/kodaiday3-05.webp";
import kodaiday306 from "../Assets/images/kodaiday3-06.webp";


import goabanner1 from "../Assets/images/goabanner1.png";
import goabanner2 from "../Assets/images/goabanner2.png";
import goabanner3 from "../Assets/images/goabanner3.png";
import goaday101 from "../Assets/images/goaday1-01.png";
import goaday102 from "../Assets/images/goaday1-02.png";
import goaday103 from "../Assets/images/goaday1-03.png";
import goaday104 from "../Assets/images/goaday1-04.png";
import goaday105 from "../Assets/images/goaday1-05.png";
import goaday106 from "../Assets/images/goaday1-06.png";
import goaday201 from "../Assets/images/goaday2-01.png";
import goaday202 from "../Assets/images/goaday2-02.png";
import goaday203 from "../Assets/images/goaday2-03.png";
import goaday204 from "../Assets/images/goaday2-04.png";
import goaday205 from "../Assets/images/goaday2-05.png";
import goaday206 from "../Assets/images/goaday2-06.png";
import goaday301 from "../Assets/images/goaday3-01.png";
import goaday302 from "../Assets/images/goaday3-02.png";
import goaday303 from "../Assets/images/goaday3-03.png";
import goaday304 from "../Assets/images/goaday3-04.png";
import goaday305 from "../Assets/images/goaday3-05.png";
import goaday306 from "../Assets/images/goaday3-06.png";


import munnarbanner1 from "../Assets/images/munnarbanner1.png";
import munnarbanner2 from "../Assets/images/munnarbanner2.png";
import munnarbanner3 from "../Assets/images/munnarbanner3.png";
import munnarday101 from "../Assets/images/munnarday1-01.png";
import munnarday102 from "../Assets/images/munnarday1-02.png";
import munnarday103 from "../Assets/images/munnarday1-03.png";
import munnarday104 from "../Assets/images/munnarday1-04.png";
import munnarday105 from "../Assets/images/munnarday1-05.png";
import munnarday106 from "../Assets/images/munnarday1-06.png";
import munnarday201 from "../Assets/images/munnarday2-01.png";
import munnarday202 from "../Assets/images/munnarday2-02.png";
import munnarday203 from "../Assets/images/munnarday2-03.png";
import munnarday204 from "../Assets/images/munnarday2-04.png";
import munnarday205 from "../Assets/images/munnarday2-05.png";
import munnarday206 from "../Assets/images/munnarday2-06.png";
import munnarday301 from "../Assets/images/munnarday3-01.png";
import munnarday302 from "../Assets/images/munnarday3-02.png";
import munnarday303 from "../Assets/images/munnarday3-03.png";
import munnarday304 from "../Assets/images/munnarday3-04.png";
import munnarday305 from "../Assets/images/munnarday3-05.png";
import munnarday306 from "../Assets/images/munnarday3-06.png";


import manalibanner1 from "../Assets/images/manalibanner1.png";
import manalibanner2 from "../Assets/images/manalibanner2.png";
import manalibanner3 from "../Assets/images/manalibanner3.png";


import alleppeymunnarhoneymoon from "../Assets/images/alleppeymunnaroffer.jpg";
import ootyhoneymoon from "../Assets/images/ootyhoneymoon.jpg";
import manalihoneymoon from "../Assets/images/manalihoneymoonoffer.jpg";
import manalioffer from "../Assets/images/manalioffer.jpg";
import kodaikanaloffer from "../Assets/images/kodaikanaloffer.jpg";
import coorgoffer from "../Assets/images/coorgoffer.jpg";


const images = {
    logo: Logo,
    luggage:luggage,
    booking:booking,
    customer:customer,
    reward:reward,
    Group:Group,
   package1:package1,
   package2:package2,
   package3:package3,
   package4:package4,
   package5:package5,
   point:point,
   download:download,
   iconlink:iconlink,
   iconlink2:iconlink2,
   iconlink3:iconlink3,
   Google:Google,
   CouplePackage:CouplePackage,

   wayanadpack:wayanadpack,
   manalipack:manalipack,
   coorgpack:coorgpack,
   chikpack:chikpack,
   kodaikanalpack:kodaikanalpack,
   munnarpack:munnarpack,
   yercaudpack:yercaudpack,
   ootypack:ootypack,
   alleppeypack:alleppeypack,
   vagamonpack:vagamonpack,
   goapack:goapack,
   varkalapackpack:varkalapack,
   trivandrumpack:trivandrumpack,
   thekkadypack:thekkadypack,
   kovalampack:kovalampack,

   wayanadpack02:wayanadpack02,
   manalipack02:manalipack02,
   coorgpack02:coorgpack02,
   chikpack02:chikpack02,
   kodaikanalpack02:kodaikanalpack02,
   munnarpack02:munnarpack02,
   yercaudpack02:yercaudpack02,
   ootypack02:ootypack02,
   alleppeypack02:alleppeypack02,
   vagamonpack02:vagamonpack02,
   goapack02:goapack02,


//    highprice001:highprice001,
//    highprice002:highprice002,
//    highprice003:highprice003,

   Rightcircle:Rightcircle,
   leftcircle:leftcircle,
   green:green,
   CouplePackage2:CouplePackage2,
   CouplePackage3:CouplePackage3,
   person1:person1,
   person2:person2,
   person3:person3,
   packagedownload:Packagedownload,
   herobannerimg:herobannerimg, 
   ootydestination:ootydestination,
   kodaikanaldestination:kodaikanaldestination,
   chikmagalurdestination:chikmagalurdestination,
   wayanddestination:wayanddestination,
   alleppeydestionation:alleppeydestionation,
   vagamondestination:vagamondestination,
   munnardestination:munnardestination,
   coorgdestination:coorgdestination,
   goadestination:goadestination,
   andamandestination:andamandestination,
   sikkimdestination:sikkimdestination,
   manalidestination:manalidestination,
   offer1:offer1,
   offer2:offer2,
   offer3:offer3,
   offer4:offer4,
   destinations:destinations,
   packages:packages,
   assistance:assistance,
   happyclients:happyclients,
   bookings:bookings,
   experience:experience,
   ootybanner:ootybanner,

   offersimg:offersimg,

   ootycarousel01:ootycarousel01,
   ootycarousel02:ootycarousel02,
   ootycarousel03:ootycarousel03,
   ootycarousel04:ootycarousel04,

   kluneymonar:kluneymonar,
   lablooms:lablooms,
   vinayagainn:vinayagainn,
   majestic:majestic,
   silverlakeview:silverlakeview,
   bykesunshine:bykesunshine,
   sinclairsretreat:sinclairsretreat,
   mountainretreat:mountainretreat,
   hotelgrange:hotelgrange,
   hotelutimate:hotelutimate,
   yantraleisure:yantraleisure,
   delightzinn:delightzinn,
   centralresidency:centralresidency,
   kottaramresidency:kottaramresidency,

   day101:day101,
   day102:day102,
   day103:day103,
   day104:day104,
   day105:day105,
   day106:day106,
   day201:day201,
   day202:day202,
   day203:day203,
   day204:day204,
   day205:day205,
   day206:day206,
   day301:day301,
   day302:day302,
   day303:day303,
   day304:day304,
   day305:day305,
   day306:day306,

   banner1:banner1,
   banner2:banner2,
   banner3:banner3,
   banner4:banner4,

 coorgbanner1:coorgbanner1,
 coorgbanner2:coorgbanner2,
 coorgbanner3:coorgbanner3,

day101coorg:day101coorg,
day102coorg:day102coorg,
day103coorg:day103coorg,
day104coorg:day104coorg,
day105coorg:day105coorg,
day106coorg:day106coorg,
day201coorg:day201coorg,
day202coorg:day202coorg,
day203coorg:day203coorg,
day204coorg:day204coorg,
day205coorg:day205coorg,
day206coorg:day206coorg,
day301coorg:day301coorg,
day302coorg:day302coorg,
day303coorg:day303coorg,
day304coorg:day304coorg,
day305coorg:day305coorg,


wayanadbanner1:wayanadbanner1,
   wayanadbanner2:wayanadbanner2,
   wayanadbanner3:wayanadbanner3,
   wayanadday101:wayanadday101,
  wayanadday102:wayanadday102,
   wayanadday103:wayanadday103,
   wayanadday104:wayanadday104,
   wayanadday105:wayanadday105,
   wayanadday106:wayanadday106,
   wayanadday201:wayanadday201,
   wayanadday202:wayanadday202,
   wayanadday203:wayanadday203,
   wayanadday204:wayanadday204,
   wayanadday205:wayanadday205,
   wayanadday206:wayanadday206,
   wayanadday301:wayanadday301,
   wayanadday302:wayanadday302,
   wayanadday303:wayanadday303,
   wayanadday304:wayanadday304,
   wayanadday305:wayanadday305,
   wayanadday306:wayanadday306,


   chikbanner1:chikbanner1,
   chikbanner2:chikbanner2,
   chikbanner3:chikbanner3,
   chikday101:chikday101,
   chikday102:chikday102,
   chikday103:chikday103,
   chikday104:chikday104,
   chikday105:chikday105,
   chikday106:chikday106,
   chikday201:chikday201,
   chikday202:chikday202,
   chikday203:chikday203,
   chikday204:chikday204,
   chikday205:chikday205,
   chikday206:chikday206,
   chikday301:chikday301,
   chikday302:chikday302,
   chikday303:chikday303,
   chikday304:chikday304,
   chikday305:chikday305,
   chikday306:chikday306,

   kodaibanner1:kodaibanner1,
   kodaibanner2:kodaibanner2,
   kodaibanner3:kodaibanner3,
   kodaiday101:kodaiday101,
   kodaiday102:kodaiday102,
   kodaiday103:kodaiday103,
   kodaiday104:kodaiday104,
   kodaiday105:kodaiday105,
   kodaiday106:kodaiday106,
   kodaiday201:kodaiday201,
   kodaiday202:kodaiday202,
   kodaiday203:kodaiday203,
   kodaiday204:kodaiday204,
   kodaiday205:kodaiday205,
   kodaiday206:kodaiday206,
   kodaiday301:kodaiday301,
   kodaiday302:kodaiday302,
   kodaiday303:kodaiday303,
   kodaiday304:kodaiday304,
   kodaiday305:kodaiday305,
   kodaiday306:kodaiday306,

 
   goabanner1:goabanner1,
   goabanner2:goabanner2,
   goabanner3:goabanner3,
   goaday101:goaday101,
   goaday102:goaday102,
   goaday103:goaday103,
   goaday104:goaday104,
   goaday105:goaday105,
   goaday106:goaday106,
   goaday201:goaday201,
   goaday202:goaday202,
   goaday203:goaday203,
   goaday204:goaday204,
   goaday205:goaday205,
   goaday206:goaday206,
   goaday301:goaday301,
   goaday302:goaday302,
   goaday303:goaday303,
   goaday304:goaday304,
   goaday305:goaday305,
   goaday306:goaday306,


   munnarbanner1:munnarbanner1,
   munnarbanner2:munnarbanner2,
   munnarbanner3:munnarbanner3,
   munnarday101:munnarday101,
   munnarday102:munnarday102,
   munnarday103:munnarday103,
   munnarday104:munnarday104,
   munnarday105:munnarday105,
   munnarday106:munnarday106,
   munnarday201:munnarday201,
   munnarday202:munnarday202,
   munnarday203:munnarday203,
   munnarday204:munnarday204,
   munnarday205:munnarday205,
   munnarday206:munnarday206,
   munnarday301:munnarday301,
   munnarday302:munnarday302,
   munnarday303:munnarday303,
   munnarday304:munnarday304,
   munnarday305:munnarday305,
   munnarday306:munnarday306,


   manalibanner1:manalibanner1,
   manalibanner2:manalibanner2,
   manalibanner3:manalibanner3,
   ootyhoneymoon:ootyhoneymoon,
   manalihoneymoon:manalihoneymoon,
   manalioffer:manalioffer,
   kodaikanaloffer:kodaikanaloffer,
   coorgoffer:coorgoffer,
   alleppeymunnarhoneymoon:alleppeymunnarhoneymoon
};

export default images;