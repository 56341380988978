import React, { useState } from "react";
import Form from "../../CommonCompounds/Form/Form";  // Import your Form component

function Couple() {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  return (
    <>
      <div className="get-a-quote container my-40">
        <div className="row customization_bar">
          <div className="col-lg-10 text-center">
            <p>
              GET A CUSTOMIZED TOUR PACKAGE <br />
              FOR YOUR FAVOURITE DESTINATION NOW!
            </p>
          </div>
          <div className="col-lg-2 text-lg-end text-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleShowForm}
            >
              GET QUOTE
            </button>
          </div>
        </div>
      </div>

      {/* Render the Form component and pass the necessary props */}
      <Form show={showForm} handleClose={handleCloseForm} />
    </>
  );
}

export default Couple;