import React, { useState } from "react";
import images from "../../images/images";

const Itinerary = () => {
  const [loadingStates, setLoadingStates] = useState([
    [true, true, true, true, true], // Day 1: 5 images
    [true, true, true, true], // Day 2: 4 images
    [true, true, true, true], // Day 3: 4 images
  ]);

  const handleImageLoad = (dayIndex, imageIndex) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[dayIndex] = [...newStates[dayIndex]];
      newStates[dayIndex][imageIndex] = false; // Set image as loaded
      return newStates;
    });
  };

  // const imageUrls = [
  //   [
     
  //   ],
  //   [
     
  //   ],
  //   [
  //   ],
  // ];

  const inclusions = [
    "3N Manali Hotel Stay",
    "3 Breakfast & 3 Dinner at Hotel",
    "Bus ticket From Delhi-Manali-Delhi",
    "Pickup and Drop Manali by Private Cab.",
    "Pvt Cab for all sightseeing as per the itinerary.",
    "Toll, Parking, driver’s Bata, road tax & fuel charges"
  ];

  const exclusions = [
    "Any Personal expenses",
    "Meals not mentioned above.",
    "Entry Fees to Monuments and Palaces.",
    "Anything Not Mentioned in The Inclusions.",
    "Any Cost Arising Due To Natural Calamities Like Landslides, Roadblocks, Etc.",
    "Tickets, tips, guided and Entrance Fees/ Charges.",
    "All items of personal nature",
  ];


  const dayContents = [
    {
      title: "Day 1 –  Delhi to Manali ",
      description:
        "Welcome to Delhi in the evening reach at Volvo Pickup Point Majnu Ka Tilla ( RK Ashram )7:30 Pm Reporting time and  Catch the bus and start your journey towards the valley of nature and the beautiful honeymoon destination HIMACHAL PRADESH Morning Reach Manali",
    },
    {
      title: "Day 2 – Explore Manali",
      description:
        "Morning arrival at Manali Volvo Stand & Move to hotel Visiting Places -Hadimba Devi Temple ,Vashisht ,Tibetan-Monastery ,Club House ,Mall Road ,Van Vihar - Dinner+ Night stay Manali",
    },
    {
      title: "Day 3 – Manali to Solang valley",
      description:
        "Breakfast at Hotel Visiting Places -Solang Valley ,Atal Tunnel ,Sissu ,Paragliding, Zip Lying, Zorbing (own Cost) Note -Since the National Green Tribunal is allowing only limited vehicle to Rohtang pass per day. Rohtang pass will on subject to availability of permit from the SDM Office Manali on the spot with additional cost. Dinner+ Night stay Manali  ",
    },
    {
      title: "Day 4 – Kullu Nagger Castel Sightseeing",
      description:
        "Breakfast at Hotel Visiting Places -Nagger Castel ,Jagatipatt Temple, Art Gallery Dinner+ Night stay Manali  ",
    },
    {
      title: "Day 5 – Departure Manali & Drop Delhi By Volvo",
      description:
        "Breakfast + Check out Hotel proceed towards Manali Mall Road by cab. Your luggage will be placed at Volvo Bus Cloakroom and you will be dropped near the Mall road. You will be free to roam the Mall Road by own where you can enjoy some shopping. Reach the Volvo bus station by own (10 Minutes walking distance from the Mall). On the way collect your luggage from the cloakroom and board your pre booked Volvo bus for your onward journey",
    },
  ];

  return (
    <div className="container mt-5">
      <h2>
        Sightseeing{" "}
        <span className="text-warning">Itinerary</span>
      </h2>

      <div className="itinerary-section">
        {dayContents.map((content, index) => (
          <div key={index} className="mt-5">
            <h className="mt-5 itinerary-heading" style={{ color: "#333333" }}>
                  <span className="point-package px-1">
                    <img src={images.point} alt="" className="img-fluid" />
                  </span>
                  {content.title}
            </h>
            <p className="mt-4">{content.description}</p>
            {/* <div className="row mt-4 mb-4">
              {imageUrls[index].map((url, imageIndex) => (
                <div key={imageIndex} className="col-6 col-md-2 mb-2">
                  {loadingStates[index][imageIndex] && (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  <img
                    src={url}
                    alt={`img${imageIndex + 1}`}
                    className="img-fluid rounded shadow-sm"
                    onLoad={() => handleImageLoad(index, imageIndex)}
                    style={{
                      display: loadingStates[index][imageIndex] ? "none" : "block",
                    }}
                  />
                </div>
              ))}
            </div> */}
          </div>
        ))}
      </div>
      

      <div className="inclusion-exclusion">
        <div className="inclusion mt-5">
          <h2>
            Package{" "}
            <span className="text-warning">Inclusions</span>
          </h2>
          <p>
            <ul className="mt-4 overflow-auto">
              {inclusions.map((item, index) => (
                <li
                className="mt-1 d-flex align-items-center"
                key={index}
                style={{ listStyleType: "none" }}
              >
                <span
                  className="me-2"
                  style={{ color: "green", fontSize: "1.2rem" }}
                >
                  ✓
                </span>
                {item}
              </li>
              ))}
            </ul>
          </p>
        </div>

        <div className="exclusion mt-5">
          <h2>
            Package{" "}
            <span className="text-warning">Exclusions</span>
          </h2>
          <p>
            <ul className="mt-4 overflow-visible">
              {exclusions.map((item, index) => (
                <li
                className="mt-1 d-flex align-items-center"
                key={index}
                style={{ listStyleType: "none" }}
              >
                <span
                  className="me-2"
                  style={{ color: "red", fontSize: "1.0rem" }}
                >
                  ✗
                </span>
                {item}
              </li>
              ))}
            </ul>
          </p>
        </div>

      </div>

    </div>
  );
};

export default Itinerary;
