import React, { useState } from "react";
import images from "../../images/images";

const Itinerary = () => {
  const [loadingStates, setLoadingStates] = useState([
    [true, true, true, true, true], // Day 1: 5 images
    [true, true, true, true], // Day 2: 4 images
    [true, true, true, true], // Day 3: 4 images
  ]);

  const handleImageLoad = (dayIndex, imageIndex) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[dayIndex] = [...newStates[dayIndex]];
      newStates[dayIndex][imageIndex] = false; // Set image as loaded
      return newStates;
    });
  };

  const imageUrls = [
    [
      images.day101,
      images.day102,
      images.day103,
      images.day104,
      images.day105,
      images.day106
      
    ],
    [
      images.day201,
      images.day202,
      images.day203,
      images.day204,
      images.day205,
      images.day206

    ],
    [
      images.day301,
      images.day302,
      images.day303,
      images.day304,
      images.day305,
      images.day306
    ],
  ];

  const inclusions = [
    "Pickup and drop-off in Ooty by private cab",
    "Stay for 2 Nights on double occupancy (2 Adults Sharing 1 Room)",
    "CP Meal Plan – 2 Breakfasts Only (Same as number of nights)",
    "Separate Sedan Car will be used according to the itinerary",
    "Transfer taxes, parking, fuel & driver charges",
    "All applicable taxes except GST."
  ];

  const exclusions = [
    "Flights, Trains, Ferries etc",
    "Monument Entrance Fees & Camera Fees.",
    "Personal Expenses - Laundry, Shopping, Telephone bills, tips etc",
    "Any Extra services - Permits, Luggage Charges, Heater, Meals etc",
    "Adventure Activities - Safari, Rides, Surfing, Paragliding etc",
    "5% GST (Services Tax)",
    "Anything else not listed in above details"
  ];

  const dayContents = [
    {
      title: "Day 1 – Arrival Ooty (Services: Stay & Car)",
      description:
        "Upon Reaching Ooty Stop, our team will greet you and transfer you to the hotel. (Check in time will be 12.00noon and Early check-in will be subject to availability and extra charge applies). During your leisure time, explore the nearby places, On your own such as visiting the Ridge and so on. Stay overnight at a hotel.",
    },
    {
      title: "Day 2 – Ooty - Full Day sightseeing (Services: Stay, Breakfast & Car)",
      description:
        "Today after breakfast at the hotel, visit, explore the Drive to Ooty sightseeing Botanical Gardens, Rose Gardens, Doddabett a peak, Thread Garden, Tea Museum, St Stephin Church, Tea Factory & Ooty Lake & Boat Club. Evening return to Hotel. Overnight stay at Ooty",
    },
    {
      title: "Day 3 – Departure Ooty to Coonoor (Services: Breakfast & Car)",
      description:
        "After breakfast check out hotel Proceed for local sightseeing Drive to Ooty Coonoor sightseeing Visit Wax Museum, Kett i Valley View, Mrc Regiment (military Camp, Outside View Only), Dolphin Nose, Sim's Park and Lamb's Rock. Later as per the departure timings and board on Your Home town with a full of memories.The tour ends here.",
    },
  ];

  return (
    <div className="container mt-5">
      <h2>
        Sightseeing{" "}
        <span className="text-warning">Itinerary</span>
      </h2>

      <div className="itinerary-section">
        {dayContents.map((content, index) => (
          <div key={index} className="mt-5">
            <h className="mt-5 itinerary-heading" style={{ color: "#333333" }}>
                  <span className="point-package px-1">
                    <img src={images.point} alt="" className="img-fluid" />
                  </span>
                  {content.title}
            </h>
            <p className="mt-4">{content.description}</p>
            <div className="row mt-4 mb-4">
              {imageUrls[index].map((url, imageIndex) => (
                <div key={imageIndex} className="col-6 col-md-2 mb-2">
                  {loadingStates[index][imageIndex] && (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  <img
                    src={url}
                    alt={`img${imageIndex + 1}`}
                    className="img-fluid rounded shadow-sm"
                    onLoad={() => handleImageLoad(index, imageIndex)}
                    style={{
                      display: loadingStates[index][imageIndex] ? "none" : "block",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      

      <div className="inclusion-exclusion">
        <div className="inclusion mt-5">
          <h2>
            Package{" "}
            <span className="text-warning">Inclusions</span>
          </h2>
          <p>
            <ul className="mt-4 overflow-auto">
              {inclusions.map((item, index) => (
                <li
                className="mt-1 d-flex align-items-center"
                key={index}
                style={{ listStyleType: "none" }}
              >
                <span
                  className="me-2"
                  style={{ color: "green", fontSize: "1.2rem" }}
                >
                  ✓
                </span>
                {item}
              </li>
              ))}
            </ul>
          </p>
        </div>

        <div className="exclusion mt-5">
          <h2>
            Package{" "}
            <span className="text-warning">Exclusions</span>
          </h2>
          <p>
            <ul className="mt-4 overflow-visible">
              {exclusions.map((item, index) => (
                <li
                className="mt-1 d-flex align-items-center"
                key={index}
                style={{ listStyleType: "none" }}
              >
                <span
                  className="me-2"
                  style={{ color: "red", fontSize: "1.0rem" }}
                >
                  ✗
                </span>
                {item}
              </li>
              ))}
            </ul>
          </p>
        </div>

      </div>

    </div>
  );
};

export default Itinerary;
