import React from "react";
import Header from "../../CommonCompounds/Header/Header.jsx";
import Index from "../../Components/Ooty/Package01.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import CarouselPackage from "../../CommonCompounds/CarouselPackage/CarouselPackage.jsx";

function Package01(){
    return(
        <>
        <Header />
        <Index />
        <CarouselPackage />
        <Footer />
        </>
    )
}

export default Package01;