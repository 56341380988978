import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import images from '../../images/images';
import { NavLink } from 'react-router-dom';

const packages = [
  {
    id: 1,
    title: "Ooty Couple Package",
    duration: "2N3D",
    description: "Ooty Sightseeing",
    oldPrice: "₹7,000",
    price: "₹4,999",
    imageUrl: images.ootypack02,
    person: "per person",
    package: "/ootyoffer",
    rating: 4.8
  },
  {
    id: 2,
    title: "Kodaikanal Couple Package",
    duration: "2N3D",
    description: "Kodaikanal Sightseeing",
    oldPrice: "₹7,000",
    price: "₹4,999",
    imageUrl: images.kodaikanalpack02,
    person: "per person",
    package: "/kodaikanaloffer",
    rating: 4.7
  },
  {
    id: 3,
    title: "Goa Couple Package",
    duration: "2N3D",
    description: "Goa Sightseeing",
    oldPrice: "₹8,000",
    price: "₹5,999",
    imageUrl: images.goapack02,
    person: "per person",
    package: "/goa",
    rating: 4.5
  },
  {
    id: 4,  
    title: "Ooty Honeymoon Package",
    duration: "2N3D",
    description: "OOty Sightseeing",
    oldPrice: "₹8,000",
    price: "₹5,999",
    imageUrl: images.ootyhoneymoon,
    person: "per person",
    package: "/ooty",
    rating: 4.8
  },
  {
    id: 5,
    title: "Manali Honeymoon Package",
    duration: "3N4D",
    description: "Manali Sightseeing",
    oldPrice: "₹11,000",
    price: "₹8,999",
    imageUrl: images.manalihoneymoon,
    person: "per person",
    package: "/manalihoneymoon",
    rating: 4.5
  },
  {
    id: 6,
    title: "Alleppey & Munnar Honeymoon Package",
    duration: "2N3D",
    description: "Alleppey & Munnar Sightseeing",
    oldPrice: "₹12000",
    price: "₹9,999",
    imageUrl: images.alleppeymunnarhoneymoon,
    person: "per person",
    package: "/alleppeymunnarhoneymoon",
    rating: 4.7
  },
  {
    id: 7,
    title: "Kodaikanal Couple Package",
    duration: "2N3D",
    description: "Full Sightseeing , Flat Rs.1000 Off*",
    oldPrice: "₹8,000",
    price: "₹5,999",
    imageUrl: images.kodaikanaloffer,
    person: "per person",
    package: "/kodaikanal",
    rating: 4.7
  },
  {
    id: 8,
    title: "Manali Couple offer Package",
    duration: "3N4D",
    description: "Full Sightseeing , Flat Rs.1000 Off*",
    oldPrice: "₹9,500",
    price: "₹7,499",
    imageUrl: images.manalioffer,
    person: "per person",
    package: "/manali",
    rating: 4.5
  },
  {
    id: 9,
    title: "Coorg Couple offer Package",
    duration: "2N3D",
    description: "Full Sightseeing , Flat Rs.1000 Off*",
    oldPrice: "₹10,000",
    price: "₹7,999",
    imageUrl: images.coorgoffer,
    person: "per person",
    package: "/coorg",
    rating: 4.4
  },

  // Add remaining package objects here...
]; 

const PackageCard = ({ pkg }) => {
  const iconDetails = [
    { src: images.package1, text: 'PRIVATE CAB' },
    { src: images.package2, text: 'SIGHTSEEING' },
    { src: images.package3, text: 'HOTEL STAY' },
    { src: images.package4, text: 'BREAKFAST' },
    { src: images.package5, text: 'TOUR GUIDE' },
  ];

  return (
    <div className="col-md-6 col-sm-12 col-lg-4 card_space">
      {pkg.package ? (
        <a
          href={pkg.package}
          className="card"
          data-wpel-link="internal"
          rel="noopener noreferrer"
        >
          <div className="position-relative">
            <img
              src={pkg.imageUrl}
              alt={pkg.title}
              className="card-img-top p-2"
              style={{ width: '100%', height: 'auto' }}
            />
            <div className="position-absolute top-0 end-0 m-3">
              <div className="bg-success text-white rounded-pill px-2 py-1 mx-2 my-2">
                <i className="bi bi-star-fill me-1"></i>
                {pkg.rating}
              </div>
            </div>
          </div>
          <div className="card-body">
            <h3 className="card-title">{pkg.title}</h3>
            <p className="card-text">
              <span style={{ color: '#e54040' }}>{pkg.duration || 'N/A'}</span>{' '}
              {pkg.description}
            </p>
            <ul className="viewpoints-icons">
              {iconDetails.map((icon, index) => (
                <li key={index}>
                  <img src={icon.src} alt="" />
                  <br />
                  <span className="icon-text">{icon.text}</span>
                </li>
              ))}
            </ul>
            <div className="price-wrapper">
              <h3 className="price">
                {pkg.oldPrice && (
                  <span
                    style={{
                      textDecoration: 'line-through',
                      color: '#e54040',
                      marginRight: '8px',
                      fontSize: '0.8em',
                      textAlign: 'center',
                    }}
                  >
                    <span style={{ color: 'black' }}>{pkg.oldPrice}</span>
                  </span>
                )}
                {pkg.price}{' '}
                <span
                  className="perperson"
                  style={{ fontSize: '0.8em', color: '#606060' }}
                >
                  {pkg.person}
                </span>
              </h3>
            </div>
          </div>
        </a>
      ) : (
        <NavLink to={`/package/${pkg.id}`} className="card">
          {/* Similar structure for NavLink */}
        </NavLink>
      )}
    </div>
  );
};

const OfferPackageList = () => {
  return (
    <div className="container pb-4 pt-4">
      <div className="row">
        <div className="col text-center head mb-4">
          <h2>Bookonetour Offer Packages</h2>
          <p className="text-justify text-center">
            Discover our curated couple packages designed to offer unforgettable
            experiences. Explore options that cater to your preferences and make
            your moments special.
          </p>
        </div>
      </div>
      <div className="row">
        {packages.map((pkg) => (
          <PackageCard key={pkg.id} pkg={pkg} />
        ))}
      </div>
    </div>
  );
};

export default OfferPackageList;