import React, { useState } from "react";
import images from "../../images/images";

const Itinerary = () => {
  const [loadingStates, setLoadingStates] = useState([
    [true, true, true, true, true], // Day 1: 5 images
    [true, true, true, true], // Day 2: 4 images
    [true, true, true, true], // Day 3: 4 images
  ]);

  const handleImageLoad = (dayIndex, imageIndex) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[dayIndex] = [...newStates[dayIndex]];
      newStates[dayIndex][imageIndex] = false; // Set image as loaded
      return newStates;
    });
  };

  const imageUrls = [
    [
      images.day101,
      images.day102,
      images.day103,
      images.day104,
      images.day105,
    ],
    [
      images.day201,
      images.day202,
      images.day203,
      images.day204,
    ],
    [
      images.day301,
      images.day302,
      images.day303,
      images.day304,
    ],
  ];

  const inclusions = [
    "Pick up & drop services from Based on Package section",
    "Stay for 2 Nights on double occupancy (2 Adults Sharing 1 Room)",
    "CP Meal Plan – 2 Breakfasts Only (Same as number of nights)",
    "Separate A/C Sedan will be used according to the itinerary",
    "Transfer taxes, parking, fuel & driver charges"
  ];

  const exclusions = [
    "Flights, Trains, Ferries etc",
    "Sightseeing Entrance Fees & Camera Fees",
    "Personal Expenses - Laundry, Shopping, Telephone bills, tips etc",
    "Any Extra services - Permits, Luggage Charges, Heater, Meals etc",
    "Anything else not listed in above details",
    "5% GST (Services Tax)"
  ];

  const dayContents = [
    {
      title: "Day 1 – Arrival at Ooty",
      description:
        "Pick up from Coimbatore / Bangalore / Mysore / Ooty and transfer you to a pre-booked hotel in Ooty, Check in time will be 12.00noon, Early check-in subject to availability.. Visit sightseeing Botanical garden, Doddabetta peak, Rose garden, Tea museum, Wax world, Thread garden, Boat house  Overnight stay in Hotel.",
    },
    {
      title: "Day 2 – Ooty Pykara sightseeing",
      description:
        "Today rejuvenate your senses with a sightseeing tour to Pine forest,Kamaraj dam outer view,6th mile,9th mile, Pykara dam outerview, Pykara water falls, Pykara boat club.  Filmy chakkar will be another addition to our city trip where one can easily relax in the green meadows of Ooty. After sightseeing proceed back to board your train or flight for your onward journey.",
    },
    {
      title: "Day 3 – Departure Ooty to Coonoor",
      description:
        "After breakfast check out hotel enjoy a day tour to Coonoor. Sightseeing to Coonoor will include Sim's park, MRC outerview, Keti Valley View point,  Lam's rock, Dolphin nose point, Tea factory & gardens. After sightseeing proceed back to board ( Ooty / Coimbatore / Mysore/ Bangalore ) your onward journey.",
    },
  ];

  return (
    <div className="container mt-5">
      <h2>
        Sightseeing{" "}
        <span className="text-warning">Itinerary</span>
      </h2>

      <div className="itinerary-section">
        {dayContents.map((content, index) => (
          <div key={index} className="mt-5">
            <h className="mt-5 itinerary-heading" style={{ color: "#333333" }}>
                  <span className="point-package px-1">
                    <img src={images.point} alt="" className="img-fluid" />
                  </span>
                  {content.title}
            </h>
            <p className="mt-4">{content.description}</p>
            <div className="row mt-4 mb-4">
              {imageUrls[index].map((url, imageIndex) => (
                <div key={imageIndex} className="col-6 col-md-2 mb-2">
                  {loadingStates[index][imageIndex] && (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  <img
                    src={url}
                    alt={`img${imageIndex + 1}`}
                    className="img-fluid rounded shadow-sm"
                    onLoad={() => handleImageLoad(index, imageIndex)}
                    style={{
                      display: loadingStates[index][imageIndex] ? "none" : "block",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      

      <div className="inclusion-exclusion">
        <div className="inclusion mt-5">
          <h2>
            Package{" "}
            <span className="text-warning">Inclusions</span>
          </h2>
          <p>
            <ul className="mt-4 overflow-auto">
              {inclusions.map((item, index) => (
                <li
                className="mt-1 d-flex align-items-center"
                key={index}
                style={{ listStyleType: "none" }}
              >
                <span
                  className="me-2"
                  style={{ color: "green", fontSize: "1.2rem" }}
                >
                  ✓
                </span>
                {item}
              </li>
              ))}
            </ul>
          </p>
        </div>

        <div className="exclusion mt-5">
          <h2>
            Package{" "}
            <span className="text-warning">Exclusions</span>
          </h2>
          <p>
            <ul className="mt-4 overflow-visible">
              {exclusions.map((item, index) => (
                <li
                className="mt-1 d-flex align-items-center"
                key={index}
                style={{ listStyleType: "none" }}
              >
                <span
                  className="me-2"
                  style={{ color: "red", fontSize: "1.0rem" }}
                >
                  ✗
                </span>
                {item}
              </li>
              ))}
            </ul>
          </p>
        </div>

      </div>

    </div>
  );
};

export default Itinerary;
