import React from 'react'
import images from '../../images/images'

export const Homepage = () => {
  return (
    <div>
        <div className="banner_container container-fluid">
          <div className="container">
            <div className="row g-0">
              <div className="col-12 col-md-6">
                <div className="banner_content ps-2 ps-md-5">
                  <h1>MAKE YOUR TRIP AMAZING WITH US</h1>
                  <p>
                  Customize and book unforgettable vacation packages designed just for you.
                  Experience hassle-free travel with a variety of options at your fingertips.
                  Start your journey today and create lasting memories!
                  </p>
                  <div className="banner_btns">
                    <a href="">KNOW MORE</a>
                    <a href="https://wa.me/8148818018" target="_blank">Contact Now</a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <img src={images.herobannerimg} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div className="container my-80 mb-5">
          <div className="popular_section ">
            <h2 className='pb-5 pt-4'>Our Popular Recommended Locations</h2>
            <div className="location_img_container">
              <div className="single_location">
                <a href="/Ooty">
                  <img src={images.ootydestination} alt="" className="img-fluid" />  </a>
                  <div className="location_name">
                    <p>Ooty</p> 
                    <span>Tamilnadu</span>
                </div>
              </div>
              <div className="single_location">
                <a href="/kodaikanal">
                  <img src={images.kodaikanaldestination} alt="" className="img-fluid" /> </a>
                  <div className="location_name">
                    <p>Kodaikanal</p>
                    <span>Tamilnadu</span>
                </div>
              </div>
              <div className="single_location">
              <a href="/coorg">
                <img src={images.wayanddestination} alt="" className="img-fluid" /> </a>
                <div className="location_name">
                  <p>Kerala</p>
                  <span>India</span>
                </div>
              </div>
              <div className="single_location">
              <a href="/chikmangalur">
                <img src={images.coorgdestination} alt="" className="img-fluid" /> </a>
                <div className="location_name">
                  <p>Karnataka</p>
                  <span>India</span>
                </div>
              </div>
              <div className="single_location">
              <a href="/munnar">
                <img src={images.manalidestination} alt="" className="img-fluid" /> </a>
                <div className="location_name">
                  <p>Himachal</p>
                  <span>India</span>
                </div>
              </div>
              <div className="single_location">
              <a href="/wayanad">
                <img src={images.sikkimdestination} alt="" className="img-fluid" /> </a>
                <div className="location_name">
                  <p>Sikkim</p>
                  <span>India</span>
                </div>
              </div>
              <div className="single_location">
                <a href="/manali">
                <img src={images.andamandestination} alt="" className="img-fluid" /> </a>
                <div className="location_name">
                  <p>Andaman</p>
                  <span>India</span>
                </div>
              </div>
              <div className="single_location">
              <a href="/Goa">
                <img src={images.goadestination} alt="" className="img-fluid" /> </a>
                <div className="location_name">
                  <p>Goa</p>
                  <span>India</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Homepage;
