import React, { useState } from "react";
import { Carousel } from "react-bootstrap"; // Ensure Bootstrap is installed
import images from "../../images/images"; // Adjust the path to your images folder
import Form from "../../CommonCompounds/Form/Form";
import Form2 from "../../CommonCompounds/Form2/Form2";
import Itinerary from "./itineray-pack01";
import "bootstrap/dist/css/bootstrap.min.css";

function Index() {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  return (
    <>
      {/* Hero Banner with Carousel */}
      <Carousel indicators={true} controls={true}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.banner1} // Replace with actual image paths
            alt="Ooty Package"
            style={{ height: "650px", objectFit: "cover" }}
          />
          <Carousel.Caption className="text-center">
            <h1 className="text-white">
            OOTY 2NIGHTS AND 3DAYS PACKAGE
            </h1>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.banner2} // Replace with actual image paths
            alt="Ooty Package"
            style={{ height: "650px", objectFit: "cover" }}
          />
          <Carousel.Caption className="text-center">
            <h1 className="text-white">
            OOTY 2NIGHTS AND 3DAYS PACKAGE
            </h1>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={images.banner3} // Replace with actual image paths
            alt="Ooty Package"
            style={{ height: "650px", objectFit: "cover" }}
          />
          <Carousel.Caption className="text-center">
            <h1 className="text-white">
            OOTY 2NIGHTS AND 3DAYS PACKAGE
            </h1>
          </Carousel.Caption>
        </Carousel.Item>

        
      </Carousel>

      <section>
        <div className="container my-80">
            <div className="row">
                {/* Package Overview Section */}
                <div className="col-lg-8 col-md-12">

                    <div className="container ">
                        <h2>
                        Package{" "}
                        <span className="text-warning">Overview</span>
                        </h2>
                        
                        <div className="d-flex align-items-center mt-3 overflow-auto">
                        <span className="googlereviews me-2"> <h4>4.1</h4></span> 
                        <span className="googleratings me-3 mb-3">★★★★☆</span>
                        <span className="googlereviews mb-2">(361+ Google Reviews)</span>
                        </div>
                        
                        <p className="mt-1">
                        Ooty, the "Queen of Hill Stations," is a beautiful destination in Tamil Nadu, surrounded by lush green hills and tea gardens. It is known for its pleasant climate, serene lakes, and attractions like the Botanical Garden and Nilgiri Mountain Railway. The town offers breathtaking views, adventure activities, and a peaceful retreat for travelers. With its charming landscapes and rich culture, Ooty is a perfect getaway for nature lovers.                        </p>
                    </div>
                    <section>
                        <Itinerary />
                    </section>
                </div>

                {/* Quote Form Section */}
                <div className="col-md-12 col-lg-4 overview">
                <div className="sticky-form" style={{ position: 'sticky', top: '100px', zIndex: 10 }}>

                    <div className="container">
                      <div className="row d-flex jutify-content-center align-items-center bg-infooo py-4 mb-2">
                          <div className="col-5">
                              <p className="price-booknow">
                                ₹5999
                              </p>
                              <p className="perperson mb-0"style={{ fontSize: '14px', color: '#606060' }} >               
                                per person
                              </p>
                          </div>
                          <div className="col-7">
                            <button type="submit" href="" className="btn btn-bknw w-100">
                                BOOK NOW
                            </button>
                          </div>
                      </div>
                    </div>
                    <Form2 />
                </div>
                </div>

            </div>
        </div>
      </section>



      {/* Render the Form component and pass the necessary props */}
      <Form show={showForm} handleClose={handleCloseForm} />
    </>
  );
}


export default Index;
