import React, { useState } from 'react';
import images from '../../images/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { BiPhoneCall } from 'react-icons/bi';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';


const Navbarmain = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbar-container container-fluid px-5 sticky-top">
      {/* Logo and Burger Menu */}
      <div className="navbar">
        <div className="burger-menu" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        <div className="logo-container">
        <a href='/'> <img src={images.logo}  alt="Book tour Logo" className="logo img-fluid" /></a> 
        </div>

        <ul className='des_nav_items'>
            <a href="/"><li>Home</li></a>
            <a href="/about"><li>About</li></a>
            <a href="/ListingPage"><li>Packages</li></a>
            <li>Services</li>
            <a href="/contact"><li>Contact Us</li></a>
          </ul>

        <div className={`nav-items ${isOpen ? 'open' : ''}`}>
          {/* Close Button inside the menu */}
          <div className="close-menu" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <ul className='mbl_nav_items'>
            <a href="/"><li>Home</li></a>
            <a href="/about"><li>About</li></a>
            <li>Packages</li>
            <li>Services</li>
            <a href="/contact"><li>Contact Us</li></a>
            <div className="d-flex g-5 align-items-center justify-content-center">
            <div className="nav-individual">
            <a href="https://www.instagram.com/bookonetour/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} /> BOOKONETOUR
            </a>
          </div>
          <div className="nav-individual">
            <a href="https://wa.me/8148818018" target="_blank">
              <BiPhoneCall /> 81488 18018
            </a>
          </div>
          </div>

          </ul>
        </div>
        <div className="nav-social-icons">
          <div className="nav-individual">
            <a href="https://www.instagram.com/bookonetour/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} /> BOOKONETOUR
            </a>
          </div>
          <div className="nav-individual">
            <a href="https://wa.me/8148818018" target="_blank">
              <BiPhoneCall /> 81488 18018
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbarmain;
