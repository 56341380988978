import React from "react";
import images from "../../images/images";
import backgroundImage from "../../Assets/images/ootybanner.jpg";
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import Firstcarousel from "./Firstcarousel";
import Couplepackage from "../../CommonCompounds/Couplepackage/Couplepackage"

function keralalisting() {
    return (
        <>
    <div>
        <div className="container banner_img mx-auto my-5" style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className="banner_description">
              <h2>KERALA TOUR PACKAGES</h2>
            </div>
        </div>

        <Firstcarousel></Firstcarousel>

        <div className="Container-fluid my-5">
            <div className="container">
                <h4 className="h4_heading">Kerala Tour Packages</h4>
                <p>Lörem ipsum suprasogt tening oaktat epinde, nebarade plutoid av infratuna. Tebelt astronomi ohebel vårdskuld. Tegt bemådeng savist, on, exolaheten. Safalanade lan förutom homok. Intragisk sede. Svischa prer, osk valig, öskade, remeter. Diligt hexarad ode. Vuvuzela difihånade makrokåtust. Suköktiga bånera eftersom plalig cosplay. Made suprafas, stenomän spell sessade. Pivan dekavis son. Trepp plasipor religt niss. Stenos tipod, makrogt nirade. Rant förlåtturné ber hemin, pregt ladat. Personlig hybubesa. Prek astrode givis tumang. Bistik hosaktiga lånegarderob. Öskade pott i nick och vyns bingen. Soktiga donade anamäning. Nes digital valuta den episire det vill säga kån mäning. </p>
            </div>
        </div>

        <Couplepackage></Couplepackage>

        <div className="Container-fluid my-5">
            <div className="container">
                <h4 className="h4_heading">kerala Tour Packages</h4>
                <p>Lörem ipsum suprasogt tening oaktat epinde, nebarade plutoid av infratuna. Tebelt astronomi ohebel vårdskuld. Tegt bemådeng savist, on, exolaheten. Safalanade lan förutom homok. Intragisk sede. Svischa prer, osk valig, öskade, remeter. Diligt hexarad ode. Vuvuzela difihånade makrokåtust. Suköktiga bånera eftersom plalig cosplay. Made suprafas, stenomän spell sessade. Pivan dekavis son. Trepp plasipor religt niss. Stenos tipod, makrogt nirade. Rant förlåtturné ber hemin, pregt ladat. Personlig hybubesa. Prek astrode givis tumang. Bistik hosaktiga lånegarderob. Öskade pott i nick och vyns bingen. Soktiga donade anamäning. Nes digital valuta den episire det vill säga kån mäning.
                <br /> <br />
                Lörem ipsum suprasogt tening oaktat epinde, nebarade plutoid av infratuna. Tebelt astronomi ohebel vårdskuld. Tegt bemådeng savist, on, exolaheten. Safalanade lan förutom homok. Intragisk sede. Svischa prer, osk valig, öskade, remeter. Diligt hexarad ode. Vuvuzela difihånade makrokåtust. Suköktiga bånera eftersom plalig cosplay. Made suprafas, stenomän spell sessade. Pivan dekavis son. Trepp plasipor religt niss. Stenos tipod, makrogt nirade. Rant förlåtturné ber hemin, pregt ladat. Personlig hybubesa. Prek astrode givis tumang. Bistik hosaktiga lånegarderob. Öskade pott i nick och vyns bingen. Soktiga donade anamäning. Nes digital valuta den episire det vill säga kån mäning. 
                Lörem ipsum suprasogt tening oaktat epinde, nebarade plutoid av infratuna. Tebelt astronomi ohebel vårdskuld. Tegt bemådeng savist, on, exolaheten. Safalanade lan förutom homok. Intragisk sede. Svischa prer, osk valig, öskade, remeter. Diligt hexarad ode. Vuvuzela difihånade makrokåtust. Suköktiga bånera eftersom plalig cosplay. Made suprafas, stenomän spell sessade. Pivan dekavis son. Trepp plasipor religt niss. Stenos tipod, makrogt nirade. Rant förlåtturné ber hemin, pregt ladat. Personlig hybubesa. Prek astrode givis tumang. Bistik hosaktiga lånegarderob. Öskade pott i nick och vyns bingen. Soktiga donade anamäning. Nes digital valuta den episire det vill säga kån mäning.</p>
            </div>
        </div>



    </div>

    <section className="Section-time">
        <div className="container py-4 ">
            <div className="row justify-content-around">
                {/* Experience */}
                <div className="col-md-2 col-6 d-flex align-items-center py-4">
                <img src={images.experience} alt="Experience Icon" className="icon" />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">15+</span> <br /> EXPERIENCE
                    </h5>
                </div>
                </div>
                

                {/* Packages */}
                <div className="col-md-2 col-6 d-flex align-items-center py-">
                <img src={images.packages} alt="Packages Icon" className="icon" />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">100+</span> <br /> PACKAGES
                    </h5>
                </div>
                </div>

                {/* Assistance */}
                <div className="col-md-2 col-6 d-flex align-items-center py-4">
                <img
                    src={images.assistance}
                    alt="Destinations Icon"
                    className="icon"
                />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">24*7</span> <br /> ASSISTANCE
                    </h5>
                </div>
                </div>

                {/* Bookings */}
                <div className="col-md-2 col-6 d-flex align-items-center py-4">
                <img src={images.bookings} alt="Bookings Icon" className="icon" />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">500+</span> <br /> BOOKINGS
                    </h5>
                </div>
                </div>

                {/* Happy Clients */}
                <div className="col-md-2 col-12 d-flex align-items-center py-4">
                <img
                    src={images.happyclients}
                    alt="Happy Clients Icon"
                    className="icon"
                />
                <div className="ms-3">
                    <h5>
                    <span className="count py-2">1200+</span> <br /> HAPPY CLIENTS
                    </h5>
                </div>
                </div>
            </div>
        </div>
    </section>

        
             
        
        
        
        </>

    )
}


export default keralalisting;