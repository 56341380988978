import React, { useState } from "react";
import images from "../../images/images";

const Itinerary = () => {
  const [loadingStates, setLoadingStates] = useState([
    [true, true, true, true, true], // Day 1: 5 images
    [true, true, true, true], // Day 2: 4 images
    [true, true, true, true], // Day 3: 4 images
  ]);

  const handleImageLoad = (dayIndex, imageIndex) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[dayIndex] = [...newStates[dayIndex]];
      newStates[dayIndex][imageIndex] = false; // Set image as loaded
      return newStates;
    });
  };

  const imageUrls = [
    [
      images.kodaiday101,
      images.kodaiday102,
      images.kodaiday103,
      images.kodaiday104,
      images.kodaiday105,
      images.kodaiday106
    ],
    [
      images.kodaiday201,
      images.kodaiday202,
      images.kodaiday203,
      images.kodaiday204,
      images.kodaiday205,
      images.kodaiday206
    ],
    [
      images.kodaiday301,
      images.kodaiday302,
      images.kodaiday303,
      images.kodaiday304,
      images.kodaiday305,
      images.kodaiday306
    ],
  ];

 const inclusions = [
    "Pickup and drop-off in Kodaikanal by private cab",
    "Stay for 2 Nights on double occupancy (2 Adults Sharing 1 Room)",
    "CP Meal Plan – 2 Breakfasts Only (Same as number of nights)",
    "Separate A/C Sedan will be used according to the itinerary",
    "Transfer taxes, parking, fuel & driver charges",
    "All applicable taxes except GST."
  ];

  const exclusions = [
    "Flights, Trains, Ferries etc",
    "Monument Entrance Fees & Camera Fees.",
    "Personal Expenses - Laundry, Shopping, Telephone bills, tips etc",
    "Any Extra services - Permits, Luggage Charges, Heater, Meals etc",
    "Adventure Activities - Safari, Rides, Surfing, Paragliding etc",
    "5% GST (Services Tax)",
    "Anything else not listed in above details"
  ];

  const dayContents = [
    {
      title: "DAY 1 – Arrival Kodaikanal",
      description:
        "Upon Reaching Kodaikanal Bus stand our team will greet you and transfer you to the Kodaikanal Hotel. (Check in time will be 12.00noon and Early check-in will be subject to availability). During your leisure time, explore the nearby places, On your own such as visiting the Ridge. Stay overnight at hotel.",
    },
    {
      title: "DAY 2 - Kodaikanal sightseeing",
      description:
        "Today morning breakfast to Hotel and Visit to Trekking Tour place, la saleth church , Vattakanal falls , Fairy falls , Lion cave , Mountain beauty , Dolphin nose , Eco rock Stay overnight at hotel.",
    },
    {
      title: "DAY 3 - Departure Kodaikanal",
      description:
        "Morning after Breakfast, check-out from the Kodaikanal hotel. departure time, if time permits, you can visit the below Kodaikanal tourism places en-route:  Moier point  Pain forest  Guna cave ,Pillar rock ,Green valley view ,Leril falls ,Shoping ,Cokers walk,Pryant park ,Star lake(kodai city Lake) Drop at Kodaikanal Bus Stand for your return journey. Home town with a full of memories.",
    },
  ];

  return (
    <div className="container mt-5">
      <h2>
        Sightseeing{" "}
        <span className="text-warning">Itinerary</span>
      </h2>

      <div className="itinerary-section">
        {dayContents.map((content, index) => (
          <div key={index} className="mt-5">
            <h className="mt-5 itinerary-heading" style={{ color: "#333333" }}>
                  <span className="point-package px-1">
                    <img src={images.point} alt="" className="img-fluid" />
                  </span>
                  {content.title}
            </h>
            <p className="mt-4">{content.description}</p>
            <div className="row mt-4 mb-4">
              {imageUrls[index].map((url, imageIndex) => (
                <div key={imageIndex} className="col-6 col-md-2 mb-2">
                  {loadingStates[index][imageIndex] && (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  <img
                    src={url}
                    alt={`img${imageIndex + 1}`}
                    className="img-fluid rounded shadow-sm"
                    onLoad={() => handleImageLoad(index, imageIndex)}
                    style={{
                      display: loadingStates[index][imageIndex] ? "none" : "block",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      

      <div className="inclusion-exclusion">
        <div className="inclusion mt-5">
          <h2>
            Package{" "}
            <span className="text-warning">Inclusions</span>
          </h2>
          <p>
            <ul className="mt-4 overflow-auto">
              {inclusions.map((item, index) => (
                <li
                className="mt-1 d-flex align-items-center"
                key={index}
                style={{ listStyleType: "none" }}
              >
                <span
                  className="me-2"
                  style={{ color: "green", fontSize: "1.2rem" }}
                >
                  ✓
                </span>
                {item}
              </li>
              ))}
            </ul>
          </p>
        </div>

        <div className="exclusion mt-5">
          <h2>
            Package{" "}
            <span className="text-warning">Exclusions</span>
          </h2>
          <p>
            <ul className="mt-4 overflow-visible">
              {exclusions.map((item, index) => (
                <li
                className="mt-1 d-flex align-items-center"
                key={index}
                style={{ listStyleType: "none" }}
              >
                <span
                  className="me-2"
                  style={{ color: "red", fontSize: "1.0rem" }}
                >
                  ✗
                </span>
                {item}
              </li>
              ))}
            </ul>
          </p>
        </div>

      </div>

    </div>
  );
};

export default Itinerary;
