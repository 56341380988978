import React, { useState } from "react";
import images from "../../images/images";

const Itinerary = () => {
  const [loadingStates, setLoadingStates] = useState([
    [true, true, true, true, true], // Day 1: 5 images
    [true, true, true, true], // Day 2: 4 images
    [true, true, true, true], // Day 3: 4 images
  ]);

  const handleImageLoad = (dayIndex, imageIndex) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[dayIndex] = [...newStates[dayIndex]];
      newStates[dayIndex][imageIndex] = false; // Set image as loaded
      return newStates;
    });
  };

  const imageUrls = [
    [
      images.munnarday101,
      images.munnarday102,
      images.munnarday103,
      images.munnarday104,
      images.munnarday105,
      images.munnarday106
    ],
    [
      images.munnarday201,
      images.munnarday202,
      images.munnarday203,
      images.munnarday204,
      images.munnarday205,
      images.munnarday206
    ],
    [
      images.munnarday301,
      images.munnarday302,
      images.munnarday303,
      images.munnarday304,
      images.munnarday305,
      images.munnarday306
    ],
  ];

  const inclusions = [
    "1N Munnar Hotel ( Breakfast )",
    "1N Alleppey Houseboat with (Breakfast , Lunch & Dinner)",
    "Pickup and Drop Cochin by Private Cab.",
    "Pvt Cab for all sightseeing as per the itinerary.",
    "Toll, Parking, driver’s Bata, road tax & fuel charges"
  ];

  const exclusions = [
    "Any Personal expenses",
    "Meals not mentioned above.",
    "Entry Fees to Monuments and Palaces.",
    "Anything Not Mentioned in The Inclusions.",
    "Any Cost Arising Due To Natural Calamities Like Landslides, Roadblocks, Etc.",
    "Tickets, tips, guided and Entrance Fees/ Charges.",
    "All items of personal nature",
  ];


  const dayContents = [
    {
      title: "Day 1 –  Cochin- Alleppey",
      description:
        "Upon Reaching Cochin Rly station  / Bus stop, our team will greet you and transfer you to the Alleppey. After breakfast drive to Alleppey Houseboat / Resort. Check-into the Houseboat at 12 noon. Houseboat cruise covering Alleppey backwaters, villages, canals, Islands etc. The boat can stop at some of the places and you can take a village walk to see and experience the village life. Lunch, evening snacks, dinner and breakfast will be provided at the houseboat.",
    },
    {
      title: "Day 2 – Alleppey To Munnar",
      description:
        "After breakfast check out from the houseboat at 9 AM proceed to Munnar. En-route sightseeing Cheeyappara waterfalls ,Valara waterfalls ,Spice plantation ,Blossom park, Overnight stay at hotel",
    },
    {
      title: "Day 3 – Departure Munnar - Cochin",
      description:
        "After breakfast check out from the hotel proceed to Munnar sightseeing ,Top station, Eravikulam National Park, Mattupetty Dam (optional boating) ,Eco point ,Kundale lake. Later as per the departure timing proceed to Cochin Airport/Railway Station.",
    },
  ];

  return (
    <div className="container mt-5">
      <h2>
        Sightseeing{" "}
        <span className="text-warning">Itinerary</span>
      </h2>

      <div className="itinerary-section">
        {dayContents.map((content, index) => (
          <div key={index} className="mt-5">
            <h className="mt-5 itinerary-heading" style={{ color: "#333333" }}>
                  <span className="point-package px-1">
                    <img src={images.point} alt="" className="img-fluid" />
                  </span>
                  {content.title}
            </h>
            <p className="mt-4">{content.description}</p>
            <div className="row mt-4 mb-4">
              {imageUrls[index].map((url, imageIndex) => (
                <div key={imageIndex} className="col-6 col-md-2 mb-2">
                  {loadingStates[index][imageIndex] && (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  <img
                    src={url}
                    alt={`img${imageIndex + 1}`}
                    className="img-fluid rounded shadow-sm"
                    onLoad={() => handleImageLoad(index, imageIndex)}
                    style={{
                      display: loadingStates[index][imageIndex] ? "none" : "block",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      

      <div className="inclusion-exclusion">
        <div className="inclusion mt-5">
          <h2>
            Package{" "}
            <span className="text-warning">Inclusions</span>
          </h2>
          <p>
            <ul className="mt-4 overflow-auto">
              {inclusions.map((item, index) => (
                <li
                className="mt-1 d-flex align-items-center"
                key={index}
                style={{ listStyleType: "none" }}
              >
                <span
                  className="me-2"
                  style={{ color: "green", fontSize: "1.2rem" }}
                >
                  ✓
                </span>
                {item}
              </li>
              ))}
            </ul>
          </p>
        </div>

        <div className="exclusion mt-5">
          <h2>
            Package{" "}
            <span className="text-warning">Exclusions</span>
          </h2>
          <p>
            <ul className="mt-4 overflow-visible">
              {exclusions.map((item, index) => (
                <li
                className="mt-1 d-flex align-items-center"
                key={index}
                style={{ listStyleType: "none" }}
              >
                <span
                  className="me-2"
                  style={{ color: "red", fontSize: "1.0rem" }}
                >
                  ✗
                </span>
                {item}
              </li>
              ))}
            </ul>
          </p>
        </div>

      </div>

    </div>
  );
};

export default Itinerary;
