import React, { useState } from "react";
import images from "../../images/images";

const Itinerary = () => {
  const [loadingStates, setLoadingStates] = useState([
    [true, true, true, true, true], // Day 1: 5 images
    [true, true, true, true], // Day 2: 4 images
    [true, true, true, true], // Day 3: 4 images
  ]);

  const handleImageLoad = (dayIndex, imageIndex) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[dayIndex] = [...newStates[dayIndex]];
      newStates[dayIndex][imageIndex] = false; // Set image as loaded
      return newStates;
    });
  };

  const imageUrls = [
    [
      images.chikday101,
      images.chikday102,
      images.chikday103,
      images.chikday104,
      images.chikday105,
      images.chikday106
    ],
    [
      images.chikday201,
      images.chikday202,
      images.chikday203,
      images.chikday204,
      images.chikday205,
      images.chikday206
    ],
    [
      images.chikday301,
      images.chikday302,
      images.chikday303,
      images.chikday304,
      images.chikday305,
      images.chikday306
    ],
  ];

  const inclusions = [
    "Pick up & drop services from Based on Client request",
    "Stay for 2 Nights on double occupancy (2 Adults Sharing 1 Room)",
    "CP Meal Plan – 2 Breakfasts Only (Same as number of nights)",
    "Separate A/C Sedan will be used according to the itinerary",
    "Transfer taxes, parking, fuel & driver charges",
    "All applicable taxes except GST."
  ];

  const exclusions = [
    "Flights, Trains, Ferries etc",
    "Monument Entrance Fees & Camera Fees.",
    "Personal Expenses - Laundry, Shopping, Telephone bills, tips etc",
    "Any Extra services - Permits, Luggage Charges, Heater, Meals etc",
    "Adventure Activities - Safari, Rides, Surfing, Paragliding etc",
    "5% GST (Services Tax)",
    "Anything else not listed in above details"
  ];

  const dayContents = [
    {
      title: "Day 1 –  Arrival at Mysore & Chikmagalur",
      description:
        "Upon Reaching Mysore Railway Station / Bus Station, our team will greet you and transfer you to the Chikmagalur, Check-in to Chikmagalur hotel, after fresh-up proceed to visit below Chikmagalur Sightseeing ,Mullayanagiri ,Jhari Falls ,Sithalayyanagiri ,Baba Budangiri, Honnammanahalla ,Dattapeeta (Bb Hills) Stay overnight at hotel.",
    },
    {
      title: "Day 2 – Chikmagalur",
      description:
        "Today morning breakfast to Hotel and  Visit to Chikmagalur sightseeing place ,Hebbe Falls,Kalhatti Falls ,Z point ,Kemmangundi, Stay overnight at hotel.",
    },
    {
      title: "Day 3 – Departure Chikmagalur",
      description:
        "Morning after Breakfast, check-out from the Chikmagalur hotel and Drive to Mysore. Depending on your departure time, if time permits, you can visit the below Chikmagalur  tourism places enroute ,Yagatchi Backwater Aqua Sports ,En route visit to Belavadi ,Halebeedu ,Belura beautiful example of Hoysala architecture and Yagatchi Dam .Drop at Mysore Rly station / Bus Stand for your return journey. Home town with a full of memories.",
    },
  ];

  return (
    <div className="container mt-5">
      <h2>
        Sightseeing{" "}
        <span className="text-warning">Itinerary</span>
      </h2>

      <div className="itinerary-section">
        {dayContents.map((content, index) => (
          <div key={index} className="mt-5">
            <h className="mt-5 itinerary-heading" style={{ color: "#333333" }}>
                  <span className="point-package px-1">
                    <img src={images.point} alt="" className="img-fluid" />
                  </span>
                  {content.title}
            </h>
            <p className="mt-4">{content.description}</p>
            <div className="row mt-4 mb-4">
              {imageUrls[index].map((url, imageIndex) => (
                <div key={imageIndex} className="col-6 col-md-2 mb-2">
                  {loadingStates[index][imageIndex] && (
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  <img
                    src={url}
                    alt={`img${imageIndex + 1}`}
                    className="img-fluid rounded shadow-sm"
                    onLoad={() => handleImageLoad(index, imageIndex)}
                    style={{
                      display: loadingStates[index][imageIndex] ? "none" : "block",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      

      <div className="inclusion-exclusion">
        <div className="inclusion mt-5">
          <h2>
            Package{" "}
            <span className="text-warning">Inclusions</span>
          </h2>
          <p>
            <ul className="mt-4 overflow-auto">
              {inclusions.map((item, index) => (
                <li
                className="mt-1 d-flex align-items-center"
                key={index}
                style={{ listStyleType: "none" }}
              >
                <span
                  className="me-2"
                  style={{ color: "green", fontSize: "1.2rem" }}
                >
                  ✓
                </span>
                {item}
              </li>
              ))}
            </ul>
          </p>
        </div>

        <div className="exclusion mt-5">
          <h2>
            Package{" "}
            <span className="text-warning">Exclusions</span>
          </h2>
          <p>
            <ul className="mt-4 overflow-visible">
              {exclusions.map((item, index) => (
                <li
                className="mt-1 d-flex align-items-center"
                key={index}
                style={{ listStyleType: "none" }}
              >
                <span
                  className="me-2"
                  style={{ color: "red", fontSize: "1.0rem" }}
                >
                  ✗
                </span>
                {item}
              </li>
              ))}
            </ul>
          </p>
        </div>

      </div>

    </div>
  );
};

export default Itinerary;
