
// import HomePage from "../Pages/homepage/home_page";


// const router = [

    
//     { path: "/", exact: true, name: "Homepage", element:HomePage  },
     
    

// ]


// export default router;


import Home from "../Pages/homepage/home_page";
import About from "../Pages/aboutus/about_us";
import Contact from "../Pages/contactus/contact_us";
import OotyPackages from "../Pages/ootylisting/ootylisting";
import keralaPackages from "../Pages/keralalisting/keralalisting";
import Package01 from "../Pages//package01/package01";
import ooty from "../Pages/ooty/ooty";
import ootyoffer from "../Pages/ootyoffer/ootyoffer";
import kodaikanal from "../Pages/kodaikanal/kodaikanal";
import kodaikanaloffer from "../Pages/kodaikanaloffer/kodaikanaloffer";
import coorg from "../Pages/coorg/coorg";
import goa from "../Pages/goa/goa";
import wayanad from "../Pages/wayanad/wayanad";
import chikmangalur from "../Pages/chikmangalur/chikmangalur";
import munnar from "../Pages/munnar/munnar";
import alleppeymunnaroffer from "../Pages/alleppymunnaroffer/alleppymunnaroffer";
import manali from "../Pages/manali/manali";
import manalihoneymoon from "../Pages/manalioffer/manalioffer";


const router = [
    { path: "/", exact: true, name: "Home", element: Home },
    { path: "/about", exact: true, name: "About", element: About },
    { path: "/contact", exact: true, name: "Contact", element: Contact},
    { path: "/OotyPackages", exact: true, name: "ListingPage", element: OotyPackages},
    { path: "/keralaPackages", exact: true, name: "ListingPage", element: keralaPackages},
    { path: "/package01", exact: true, name: "Package01", element: Package01},
    { path: "/ooty", exact: true, name: "ooty", element: ooty},
    { path: "/kodaikanal", exact: true, name: "kodaikanal", element: kodaikanal},
    { path: "/coorg", exact: true, name: "coorg", element: coorg},
    { path: "/goa", exact: true, name: "goa", element: goa},
    { path: "/wayanad", exact: true, name: "wayanad", element: wayanad},
    { path: "/chikmangalur", exact: true, name: "chikmangalur", element: chikmangalur},
    { path: "/munnar", exact: true, name: "munnar", element: munnar},
    { path: "/ootyoffer", exact: true, name: "ootyoffer", element: ootyoffer},
    { path: "/kodaikanaloffer", exact: true, name: "kodaikanaloffer", element: kodaikanaloffer},
    
    { path: "/alleppeymunnarhoneymoon", exact: true, name: "alleppeymunnaroffer", element: alleppeymunnaroffer},
    
    { path: "/manali", exact: true, name: "manali", element: manali},
    { path: "/manalihoneymoon", exact: true, name: "manalihoneymoon", element: manalihoneymoon}


]


export default router;